/* eslint-disable prettier/prettier */
import { TypeSelect } from 'src/types/megaCategory';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const ADMIN = 'admin';
export const SUPER_ADMIN = 'super-admin';
export const STAFF = 'staff';
export const CLIENT = 'client';

export const USER_ROLES = {
  [ADMIN]: 'Admin',
  [SUPER_ADMIN]: 'Super Admin',
  [STAFF]: 'Staff',
  [CLIENT]: 'Client',
};

export const DEFAULT_ROWS_PER_PAGE = [10, 20, 50];
export const DEFAULT_PAGESIZE = DEFAULT_ROWS_PER_PAGE[0];

export const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy';
export const DISPLAY_DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';

export const INIT_PAGINATION = {
  rowsPerPage: DEFAULT_PAGESIZE,
  page: 0,
  count: 0,
};
export const FETCH_ALL_LIMIT = 999;

export const DEFAULT_ADDRESS_PAGESIZE = 3;

export const INIT_ADDRESS_PAGINATION = {
  count: 0,
  page: 0,
  rowsPerPage: DEFAULT_ADDRESS_PAGESIZE,
};

export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
export const DEFAULT_CURRENCY_CODE = process.env.REACT_APP_DEFAULT_CURRENCY_CODE;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_MAPYCZ_API_URL;
export const { NODE_ENV } = process.env;

export const defaultAddressCoords = `{
  "lat": null,
  "lon": null
}`;

export const LOCALES = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'vi',
    label: 'Việt Nam',
  },
  {
    value: 'cs',
    label: 'Czech',
  },
];

export const LOCALE_CODES: string[] = LOCALES.map((locale: TypeSelect) => locale.value);

export const defaultLocaleValue = { en: '', vi: '' };

export const validPostcodes = [
  {
    from: 10000,
    to: 19999,
  },
  {
    from: 25000,
    to: 29500,
  },
  {
    from: 31000,
    to: 36499,
  },
  {
    from: 37000,
    to: 44199,
  },
  {
    from: 46000,
    to: 47399,
  },
  {
    from: 50000,
    to: 57299,
  },
  {
    from: 58000,
    to: 59500,
  },
  {
    from: 60000,
    to: 69899,
  },
  {
    from: 70000,
    to: 76900,
  },
  {
    from: 78300,
    to: 79899,
  },
];

export const imageTypes = ['jpeg', 'jpg', 'gif', 'tiff', 'psd', 'eps', 'ai', 'png'];

export const ORDER_TYPE = {
  DRAFT: 'draft_order',
  PLACED: 'placed_order',
  RETURNED: 'returned_order',
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  PROCESS: 'process',
  DELIVERY: 'delivery',
  SUCCESS: 'success',
  CANCEL: 'cancel',
};

export const PAYMENT_METHODS = {
  CASH: 'cash',
  BANK_TRANSFER: 'bank_transfer',
};

export const ORDER_TRACKING_TYPE = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
};

export const positions = [
  { title: 'staff-order', value: 'staff_order' },
  { title: 'staff-goods', value: 'staff_goods' },
  { title: 'staff-delivery', value: 'staff_delivery' },
];
