import { CssBaseline, ThemeProvider } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import RTL from './components/RTL';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import ConfirmDialog from './components/ConfirmDialog';
import Loading from './components/commons/Loading';
import useAuth from './hooks/useAuth';
import { useFirebase } from './hooks/useFirebase';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import './i18n';
import gtm from './lib/gtm';
import { demoRoutes } from './routes';
import { useSelector } from './store';
import { createCustomTheme } from './theme';

import './global.scss';

const App: FC = () => {
  const content = useRoutes(demoRoutes);
  const { settings } = useSettings();
  const requestCount = useSelector((state) => state.app.requestCount);

  const { isInitialized, user } = useAuth();
  useFirebase(user?.id);
  useScrollReset();
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        <SettingsDrawer />
        {isInitialized ? content : <SplashScreen />}
        <Loading open={requestCount > 0} />
        <ConfirmDialog />
      </RTL>
    </ThemeProvider>
  );
};

export default App;
