import { useState, useEffect, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { fcmApi } from 'src/api/notificationApi';
import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
};

export const useFirebase = (userId: string) => {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const handleIncomingMessage = useCallback((payload) => {
    // TODO: custom the notification here
    toast.success(payload.notification.title);
    setMessage(payload);
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};
    const initializeFirebase = async () => {
      if (userId) {
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        try {
          await Notification.requestPermission();
          const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY });
          if (currentToken) {
            setToken(currentToken);
            await fcmApi.subscribeTopic(currentToken, userId);
          } else {
            console.log("Don't have token now");
          }
          if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            console.log('Service Worker registered with scope:', registration);
          }
        } catch (err) {
          console.error(err);
        }
        unsubscribe = onMessage(messaging, handleIncomingMessage);
        setIsInitialized(true);
      } else {
        setToken(null);
        setMessage(null);
        setIsInitialized(false);
      }
    };

    void initializeFirebase();

    return () => {
      unsubscribe();
      setIsInitialized(false);
    };
  }, [userId]);

  return { token, message, isInitialized };
};
