import type { ListProps } from '@material-ui/core';
import { List, ListSubheader } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  clickable?: boolean;
  roles?: string[];
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
  roles?: string[];
}

const renderNavItems = (
  {
    depth = 0,
    items,
    pathname,
  }: {
    items: Item[];
    pathname: string;
    depth?: number;
  },
  userRole: string,
): JSX.Element => {
  const listItems = items.reduce(
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    (acc, item) =>
      reduceChildRoutes(
        {
          acc,
          item,
          pathname,
          depth,
        },
        userRole,
      ),
    [],
  );

  return !!listItems?.length && <List disablePadding>{listItems}</List>;
};

const reduceChildRoutes = (
  {
    acc,
    pathname,
    item,
    depth,
  }: {
    acc: JSX.Element[];
    pathname: string;
    item: Item;
    depth: number;
  },
  userRole: string,
): Array<JSX.Element> => {
  if (item.roles?.length && !item.roles.includes(userRole)) return acc;

  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname,
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname,
        )
      : false;

    const navItems = renderNavItems(
      {
        depth: depth + 1,
        items: item.children,
        pathname,
      },
      userRole,
    );
    if (navItems) {
      acc.push(
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          clickable={item.clickable}
          roles={item.roles}
        >
          {navItems}
        </NavItem>,
      );
    }
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        clickable={item.clickable}
        roles={item.roles}
      />,
    );
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, title, roles, ...other } = props;
  const { t } = useTranslation();

  const { user } = useAuth();
  const userRole = user.role;
  if (roles?.length && !roles.includes(userRole)) return null;

  const navItems = renderNavItems(
    {
      items,
      pathname,
    },
    userRole,
  );

  return (
    navItems && (
      <List
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: 'text.primary',
              fontSize: '0.75rem',
              lineHeight: 2.5,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            {t(title)}
          </ListSubheader>
        }
        {...other}
      >
        {navItems}
      </List>
    )
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
