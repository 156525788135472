import axios from 'axios';
import { isNil, omitBy, isEmpty, isBoolean } from 'lodash';
import { ProductCUFormValues } from 'src/components/product/CUForm';

import { DEFAULT_PAGESIZE } from 'src/constants';

import { IPagination, ResponseDataPaging } from 'src/types/data';
import { Product, ProductParams } from 'src/types/product';
// eslint-disable-next-line import/namespace
import { API_URL } from './api';

class ProductAPI {
  async getList(
    params?: ProductParams,
    pagination?: Omit<IPagination, 'count'>,
  ): Promise<ResponseDataPaging<Product[]> | null> {
    let query = new URLSearchParams(omitBy(params, isEmpty)).toString();
    if (isBoolean(params.outOfStock)) {
      query += `&outOfStock=${params.outOfStock.toString()}`;
    }

    const response = await axios.get(
      `${API_URL.PRODUCT}?${query}&limit=${pagination.rowsPerPage || DEFAULT_PAGESIZE}&offset=${
        pagination.rowsPerPage * pagination.page || 0
      }`,
    );
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getManufacturer(): Promise<ResponseDataPaging<string[]> | null> {
    const response = await axios.get(`${API_URL.PRODUCT}/manufacturer`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getOriginCountry(): Promise<ResponseDataPaging<string[]> | null> {
    const response = await axios.get(`${API_URL.PRODUCT}/origin-country`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async deleteById(id: string): Promise<{ success: boolean }> {
    const response = await axios.delete(`${API_URL.PRODUCT}/${id}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async post(params: ProductCUFormValues): Promise<{ success: boolean }> {
    const response = await axios.post(`${API_URL.PRODUCT}`, params);

    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async patch(id: string, params: ProductCUFormValues): Promise<{ success: boolean }> {
    const response = await axios.patch(`${API_URL.PRODUCT}/${id}`, params);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async check(name: string, value: string): Promise<{ success: boolean }> {
    const response = await axios.get(`${API_URL.SEARCH}?name=${name}&value=${value}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }
}

export const productAPI = new ProductAPI();
