const apiRoot = process.env.REACT_APP_NOTIFICATION_API_URL;
class FcmApi {
  async subscribeTopic(token: string, userId: string): Promise<any> {
    try {
      await fetch(`${apiRoot}/api/notifications/subscribe-to-topics`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ userId, token }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        success: true,
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }
}

export const fcmApi = new FcmApi();
