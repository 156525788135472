import axios from 'axios';
import { isNil, omitBy } from 'lodash';
import { Order, OrderParams, OrderV2OverviewData, DraftOrderInput } from 'src/types/order';

import { API_URL } from './api';

class OrderAPI {
  async getList(params?: OrderParams): Promise<{ orders?: Order[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(params, isNil)).toString();

    const response = await axios.get(`${API_URL.ORDER}?${query}`);
    if (response?.status === 200) {
      const { orders, total } = response?.data?.data?.attributes;
      return { orders, total, success: true };
    }
    return null;
  }

  async getOrderOverView(): Promise<{ overview?: OrderV2OverviewData[]; total?: number; success: boolean }> {
    const response = await axios.get(`${API_URL.ORDER}/overview`);

    if (response?.data?.data?.attributes?.overview) {
      const overview = response?.data?.data?.attributes?.overview;
      return { overview, success: true };
    }
    return null;
  }

  async copyOrder(id: string): Promise<{ total?: number; success: boolean }> {
    const response = await axios.post(`${API_URL.ORDER}/${id}/clone`);
    if (response?.data?.data?.id) {
      return { success: true };
    }
    return null;
  }

  async deleteOrder(id: string): Promise<{ total?: number; success: boolean }> {
    const response = await axios.delete(`${API_URL.ORDER}/${id}`);
    if (response?.data?.data?.attributes.success) {
      return { success: true };
    }
    return null;
  }

  async cancelOrder(id: string): Promise<{ total?: number; success: boolean }> {
    const response = await axios.delete(`${API_URL.ORDER}/${id}/cancel`);
    if (response?.data?.data?.attributes.success) {
      return { success: true };
    }
    return null;
  }

  async createAxiosOrder(order: DraftOrderInput): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.ORDER}`, order);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateAxiosOrder(
    id: string,
    order: DraftOrderInput,
  ): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.put(`${API_URL.ORDER}/${id}`, order);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getAxiosOrderById(id: string): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.ORDER}/${id}`);

      return { success: true, data: { ...response.data?.data?.attributes, id } };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}

export const orderAPIV2 = new OrderAPI();
