import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userApi } from 'src/api/userApi';
import { CLIENT, DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';

import { AppThunk } from 'src/store';
import { CustomerFilterValues, CustomerParams } from 'src/types/cutomers';
import { DataPaging, IPagination } from 'src/types/data';
import { Order, OrderFilterValues, OrderParams } from 'src/types/order';
import { User } from 'src/types/users';
import { IAddress } from '../types/address';

export type CustomerState = {
  data: DataPaging<User[]>;
  params: CustomerParams;
  filter: CustomerFilterValues;
  currentCustomer: User | null;
  currentCustomerAddresses?: IAddress[];
  customerOrder: {
    data: DataPaging<Order[]>;
    params: OrderParams;
    filter: OrderFilterValues;
  };
  customerOrderOverview: {
    total?: string;
    totalAmount?: number;
    totalAmountVat?: number;
  };
};

export const initialState: CustomerState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
  currentCustomer: null,
  currentCustomerAddresses: [],
  customerOrder: {
    data: {
      data: [],
      pagination: INIT_PAGINATION,
      mapping: {},
    },
    params: {},
    filter: {},
  },
  customerOrderOverview: {},
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setDataCustomer(
      state,
      action: PayloadAction<{ data: DataPaging<User[]> | null; params: CustomerParams; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataCustomer(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
    setCurrentCustomer(state, action: PayloadAction<User | null>) {
      state.currentCustomer = action.payload;
    },
    setCurrentCustomerAddresses(state, action: PayloadAction<IAddress[]>) {
      state.currentCustomerAddresses = action.payload;
    },
    setDataCustomerOrder(
      state,
      action: PayloadAction<{ data: DataPaging<Order[]> | null; params: OrderParams; filter: any }>,
    ) {
      state.customerOrder.data = action.payload.data;
      state.customerOrder.filter = action.payload.filter;
      state.customerOrder.params = action.payload.params;
    },
    resetDataCustomerOrder(state) {
      state.customerOrder.data = initialState.customerOrder.data;
      state.customerOrder.params = initialState.customerOrder.params;
      state.customerOrder.filter = initialState.customerOrder.filter;
    },
    setCustomerOrderOverview(state, action: PayloadAction<any>) {
      state.customerOrderOverview = action.payload;
    },
    setCurrentCustomerInfoAndAddress(
      state,
      action: PayloadAction<{
        customer?: User;
        addresses?: IAddress[];
      }>,
    ) {
      state.currentCustomer = action.payload.customer || null;
      state.currentCustomerAddresses = action.payload.addresses || [];
    },
  },
});
export const {
  reducer,
  actions: {
    setDataCustomer,
    resetDataCustomer,
    setCurrentCustomer,
    setCurrentCustomerAddresses,
    setDataCustomerOrder,
    resetDataCustomerOrder,
    setCustomerOrderOverview,

    setCurrentCustomerInfoAndAddress,
  },
} = customersSlice;

export default customersSlice;

export const getListCustomer =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: CustomerFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: CustomerParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      postcode: params.postcode,
      status: params.status,
      role: CLIENT,
    };
    const data = await userApi.getAxiosList(filterValues as any);

    if (data) {
      dispatch(
        setDataCustomer({
          data: {
            data: data.users,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

type IConfig = { onSuccess?: (res?: DataPaging<User[]>) => void; isSaveDataToRedux?: boolean };

export const getAllCustomer =
  (
    {
      filter,
      pagination,
      params,
    }: {
      filter?: CustomerFilterValues;
      pagination?: Omit<IPagination, 'count'>;
      params?: CustomerParams;
    },
    config?: IConfig,
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      role: CLIENT,
      orderBy: 'full_name',
      orderDirection: 'asc',
      searchValue: params.searchValue,
    };

    const data = await userApi.getAxiosList(filterValues as any);

    const { onSuccess, isSaveDataToRedux = true } = config;

    if (data) {
      const returnedData = {
        data: {
          data: data.users,
          pagination: {
            ...pagination,
            count: data.total,
          },
        },
        filter,
        params,
      };
      if (isSaveDataToRedux) {
        dispatch(setDataCustomer(returnedData));
      }
      onSuccess?.(returnedData.data);
    }
  };

export const createCustomer =
  ({ values, onSuccess, onError }: { values: User; onSuccess?: (res: User) => void; onError?: () => void }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await userApi.createAxiosUser(values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const updateCustomer =
  ({
    values,
    onSuccess,
    onError,
    id,
  }: {
    id: string;
    values: User;
    onSuccess?: (res: User) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await userApi.updateAxiosUser(id, values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const deleteCustomer =
  ({
    onSuccess,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await userApi.deleteAxiosUser(id);

      if (response.success) {
        onSuccess?.();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

export const deactiveCustomer =
  ({
    onSuccess,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await userApi.deactiveAxiosUser(id);

      if (response.success) {
        onSuccess?.();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

export const approveCustomer =
  ({
    onSuccess,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await userApi.approveAxiosUser(id);

      if (response.success) {
        onSuccess?.();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

export const getCustomerById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await userApi.getAxiosUserById(id);

      if (response.success) {
        disptach(setCurrentCustomer(response.data));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

export const getCustomerAddressById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await userApi.getAxiosAddressByUserId(id);

      if (response.success) {
        disptach(setCurrentCustomerAddresses(response.data));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

export const getCustomerInfoAndAddressById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const [customerRes, addressRes] = await Promise.all([
        userApi.getAxiosUserById(id),
        userApi.getAxiosAddressByUserId(id),
      ]);
      const returnedData: {
        customer?: User;
        addresses?: IAddress[];
      } = {};

      if (customerRes.success) {
        returnedData.customer = customerRes.data;
      }
      if (addressRes.success) {
        returnedData.addresses = addressRes.data;
      }
      disptach(setCurrentCustomerInfoAndAddress(returnedData));
    } catch (error) {
      console.log('error', error);
    }
  };

export const getListOrderByCustomer =
  (
    id: string,
    {
      filter,
      pagination,
      params,
    }: {
      filter?: OrderFilterValues;
      pagination: Omit<IPagination, 'count'>;
      params: OrderParams;
    },
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      postcode: params.postcode,
      status: params.status,
      type: params.type,
    };
    const data = await userApi.getAxiosListOrderByUser(id, filterValues);

    if (data) {
      dispatch(
        setDataCustomerOrder({
          data: {
            data: data.orders,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

export const getOrderOverviewDataByUser =
  (id: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await userApi.getAxiosOrderOverviewByUser(id);

      if (response.success) {
        dispatch(setCustomerOrderOverview(response.overview));
      }
    } catch (error) {
      console.error(error);
    }
  };
